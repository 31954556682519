<template>
  <div id="dashboard-page">
    <h1 id="page-title" class="h2">Patiënt</h1>

    <div class="row mb-4">
      <div class="col">
        <div id="primary-card" class="card">
          <div class="card-body p-0 d-flex" style="min-height: 138px;">
            <img class="patient-photo rounded-circle mr-4" src="https://randomuser.me/api/portraits/lego/1.jpg">
            <div class="d-flex flex-column flex-fill p-3">
              <div class="flex-fill">
                <h2>Mevr. {{ user.name }}</h2>
                <ul class="list-inline">
                  <li class="list-inline-item"><font-awesome-icon :icon="['fas', 'phone-alt']"/> {{ user.phone }}</li>
                  <li class="list-inline-item"><font-awesome-icon :icon="['fas', 'envelope']"/> {{ user.email }}</li>
                </ul>
              </div>
              <img @click='isHidden = !isHidden' class="d-block mx-auto" :src="require('@/assets/img/chevron-down.svg')">
              <transition name="slide">
                <div v-show='!isHidden'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia risus vel euismod pellentesque. Sed est turpis, elementum eget ligula non, consequat molestie leo. Curabitur vel tortor sit amet quam efficitur malesuada. Nullam nec semper ligula. Nunc venenatis nibh suscipit rhoncus porttitor. Nullam finibus eros sed consequat vestibulum. Sed fringilla metus id erat venenatis porttitor. Praesent gravida in velit ut pellentesque.</p>
                  <p>Proin suscipit luctus eros sit amet consectetur. Ut varius leo vel tempus blandit. Sed dictum metus non ultricies feugiat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac justo sit amet enim gravida ultricies quis in diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non euismod neque. Proin leo dolor, finibus eu rutrum eu, lacinia id purus. Ut eget sapien vulputate, accumsan nibh nec, interdum ante.</p>
                </div>
              </transition>
            </div>
            <div style="width: 138px; flex: 1 0 auto;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-secondary font-weight-bold mb-4">Diagnose</div>

    <div class="row">
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
    </div>

    <div class="text-secondary font-weight-bold mb-4">Behandelplan</div>

    <div class="row">
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 300px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card">
          <div class="card-body" style="height: 300px">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Patient',
  data () {
    return {
      isHidden: true,
      user: {}
    }
  },
  mounted () {
    this.fetchUser()
  },
  methods: {
    fetchUser () {
      return this.$http
        .get('//jsonplaceholder.typicode.com/users/' + this.$route.params.patientId)
        .then((response) => {
          this.user = response.data
        })
        .catch(error => console.log(error))
    }
  }
}
</script>

<style lang="scss">
#page-title {
  margin-bottom: 40px;
}

.card {
  border-radius: 10px;
  border: none;
  box-shadow: 0 10px 20px rgba(0,0,0, 0.03);
}

#primary-card {
  background-color: #428AF7;
  color: #fff;
  border-radius: 69px 10px 10px 69px;
}

.patient-photo {
  max-height: 138px;
}
</style>
